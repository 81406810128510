import {useEffect, useMemo, useState} from 'react';

import client from '@/shared/api/client';
import {weaver} from '@/shared/lib/weaver';
import useCurrentUserQuery from '@/shared/queries/useCurrentUserQuery';
import {Button} from '@/shared/ui/button';
import {Card} from '@/shared/ui/card';
import {DropDown} from '@/shared/ui/dropdown';
import Message from '@/shared/ui/message';
import * as Sentry from '@sentry/react';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {FlexBox, useToast} from '@wanteddev/wds';

import useGetCourseNamesQuery from '../Course/queries/useGetCourseNames';

import CircleCheckBox from './components/CircleCheckBox';
import DeleteConfirmModal from './components/DeleteConfirmModal';
import {CourseCompletionDataDownloadPanel} from './components/download-panel';
import GraduateTable from './components/GraduateTable';
import useGetGraduateBriefQuery, {
  getGraduateBriefQueryKeys,
} from './queries/useGetGraduateBriefQuery';

import styles from './Graduate.module.scss';

const Graduate: React.FC = () => {
  const toast = useToast();
  const {user} = useCurrentUserQuery();
  const institutionId = user?.institutionId;
  const queryClient = useQueryClient();
  const [isViewOnlyEmployedUsers, setIsViewOnlyEmployedUsers] = useState(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false);

  const {data: courseNames} = useGetCourseNamesQuery(institutionId, {
    suspense: true,
    select: data =>
      data?.sort((a, b) => a.name.localeCompare(b.name, 'ko')) || [],
  });
  const coursesDropDownOptions = useMemo(
    () => courseNames?.map(c => ({name: c.name, value: String(c.courseId)})),
    [courseNames]
  );

  const [selectedCourseId, setSelectedCourseId] = useState<string>();

  const {data, isLoading} = useGetGraduateBriefQuery(
    institutionId,
    selectedCourseId,
    {
      enabled: Boolean(selectedCourseId),
      select: data => {
        return {
          ...data,
          // 이름, 연락처, 이메일이 동일하면 중복 제거
          graduateBriefs: data.graduateBriefs.reduce(
            (acc, cur) => {
              const isExist = acc.some(
                item =>
                  item.graduateName === cur.graduateName &&
                  item.graduateMobile === cur.graduateMobile &&
                  item.graduateEmail === cur.graduateEmail
              );
              if (!isExist) {
                acc.push(cur);
              }
              return acc;
            },
            [] as typeof data.graduateBriefs
          ),
        };
      },
    }
  );

  const handleCourseChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCourseId(e.currentTarget.value);
    setIsViewOnlyEmployedUsers(false);
  };

  const deleteUsersMutation = useMutation({
    mutationFn: () =>
      client.put(
        `/eas/dashboard/institution/${institutionId}/course/graduate`,
        {graduateIds: selectedIds}
      ),
    onSuccess: () => {
      setSelectedIds([]);
      queryClient.invalidateQueries({
        queryKey: getGraduateBriefQueryKeys(institutionId, selectedCourseId),
      });
    },
    onError: error => {
      console.error(error);
      Sentry.captureException(error);
      toast({
        variant: 'warning',
        content: '삭제에 실패하였습니다. 다시 시도해보세요.',
      });
    },
  });

  // 취업 확인된 수료생만 보기 클릭
  const handleClickShowEmployedUsers: React.ChangeEventHandler<
    HTMLInputElement
  > = e => {
    setIsViewOnlyEmployedUsers(e.currentTarget.checked);

    // send event traking
    weaver.sendEvent('eas__user__filter__click', {
      filterKind: '취업확인여부',
      isChecked: String(e.currentTarget.checked),
    });
  };

  const formatDate = (date?: string) => {
    return date?.replaceAll('-', '.') || '';
  };

  useEffect(() => {
    weaver.sendEvent('eas__dashboard__view');
  }, []);

  if (!courseNames?.length) {
    return <Message message="등록된 코스가 없습니다." />;
  }

  return (
    <>
      <CourseCompletionDataDownloadPanel institutionId={institutionId} />
      <Card className={styles.Graduate}>
        <section className={styles.filterSection}>
          <DropDown
            noBorder
            selectClassName={styles.courseSelect}
            optionClassName={styles.courseOption}
            value={selectedCourseId}
            dataList={coursesDropDownOptions}
            onChange={handleCourseChange}
            placeholder="코스를 선택해주세요"
          />
        </section>
        <section className={styles.infoSection}>
          <FlexBox flexDirection="column" flex="1">
            {data && (
              <>
                <dl className={styles.courseDurationText}>
                  <dt className={styles.courseStartDate}>교육 시작일</dt>
                  <dd>{formatDate(data.startDate)}</dd>
                  <dt className={styles.courseEndDate}>교육 종료일</dt>{' '}
                  <dd>{formatDate(data.endDate)}</dd>
                  <dt className={styles.trackingEndDate}>취업 트래킹 종료일</dt>
                  <dd>{formatDate(data.trackingEndDate)}</dd>
                </dl>
                <div className={styles.hintMessage}>
                  * 수료생 이름 클릭 시 해당 수료생의 취업활동 상세 내역을 볼 수
                  있습니다.
                </div>
              </>
            )}
          </FlexBox>
          <CircleCheckBox
            label="취업 확인된 수료생만 보기"
            checked={isViewOnlyEmployedUsers}
            disabled={!data?.graduateBriefs?.length}
            onChange={handleClickShowEmployedUsers}
          />
          <Button
            size="small"
            variant="outlined"
            className={styles.deleteButton}
            disabled={!selectedIds?.length}
            onClick={() => setOpenDeleteConfirmModal(true)}
          >
            선택 삭제
          </Button>
        </section>
        <GraduateTable
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          institutionId={institutionId}
          courseId={selectedCourseId}
          data={data?.graduateBriefs ?? []}
          isLoading={isLoading}
          viewOnlyEmployedUsers={isViewOnlyEmployedUsers}
        />
      </Card>

      <DeleteConfirmModal
        open={openDeleteConfirmModal}
        onClose={() => setOpenDeleteConfirmModal(false)}
        onDelete={() => {
          deleteUsersMutation.mutate();
        }}
      />
    </>
  );
};

export default Graduate;
