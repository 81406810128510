import {Loading} from '@/shared/ui/loading';

export const GraduateLoading: React.FC = () => {
  return (
    <tr>
      <td colSpan={11} align="center">
        <Loading />
      </td>
    </tr>
  );
};
