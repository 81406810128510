import {SortButton} from '@/shared/ui/sort-button';
import {TableHead} from '@/shared/ui/table/table-head';

import PassnoteApplyList from '../components/PassnoteApplyList';
import {PassnoteApplyPass} from '../queries/usePassnoteApplyPassListQuery';

import styles from './PassnoteApplyPassList.module.scss';

const PassnoteApplyPassListDummy: React.FC = () => {
  const sortedData: Partial<PassnoteApplyPass>[] = [
    {
      graduateId: 1,
      courseName: 'PO 챌린지 3차',
      graduateName: '심',
      applyPassTime: '2023.11.09',
    },
    {
      graduateId: 2,
      courseName: 'PO 챌린지 3차',
      graduateName: '박',
      applyPassTime: '2023.11.09',
    },
    {
      graduateId: 3,
      courseName: 'PO 챌린지 2차',
      graduateName: '김',
      applyPassTime: '2023.11.09',
    },
    {
      graduateId: 4,
      courseName: '마케팅 챌린지 6차',
      graduateName: '이',
      applyPassTime: '2023.11.08',
    },
    {
      graduateId: 5,
      courseName: '안드로이드 챌린지 4차',
      graduateName: '이',
      applyPassTime: '2023.11.08',
    },
    {
      graduateId: 6,
      courseName: 'FE 챌린지 14차',
      graduateName: '김',
      applyPassTime: '2023.11.07',
    },
    {
      graduateId: 7,
      courseName: '마케팅 챌린지 6차',
      graduateName: '박',
      applyPassTime: '2023.11.07',
    },
    {
      graduateId: 8,
      courseName: '마케팅 챌린지 6차',
      graduateName: '서',
      applyPassTime: '2023.11.08',
    },
    {
      graduateId: 9,
      courseName: '안드로이드 챌린지 4차',
      graduateName: '최',
      applyPassTime: '2023.11.08',
    },
    {
      graduateId: 10,
      courseName: 'FE 챌린지 14차',
      graduateName: '정',
      applyPassTime: '2023.11.07',
    },
    {
      graduateId: 11,
      courseName: '마케팅 챌린지 6차',
      graduateName: '김',
      applyPassTime: '2023.11.07',
    },
  ];

  return (
    <PassnoteApplyList
      title="이력서 합격자 리스트"
      listCount={sortedData?.length}
    >
      <table>
        <colgroup>
          <col width="20%" />
          <col width="40%" />
          <col width="23%" />
          <col width="17%" />
        </colgroup>
        <TableHead>
          <th>이름</th>
          <th>코스명</th>
          <th>
            <SortButton onClick={null} direction={null} text="이력서 합격일" />
          </th>
          <th>상세</th>
        </TableHead>
        <tbody>
          {sortedData?.map(item => (
            <tr key={`${item.courseId}_${item.graduateId}_${item.applyId}`}>
              <td>{item.graduateName}**</td>
              <td>{item.courseName}</td>
              <td>{item.applyPassTime}</td>
              <td className={styles.linkButton}>확인하기</td>
            </tr>
          ))}
        </tbody>
      </table>
    </PassnoteApplyList>
  );
};

export default PassnoteApplyPassListDummy;
