import {css} from '@wanteddev/wds';

export const descriptionStyle = css`
  margin-top: 20px;
  color: #8a8a8a;
  letter-spacing: 0;
  > li {
    display: flex;
    gap: 3px;
    & + li {
      margin-top: 3px;
    }
    ::before {
      content: '*';
      display: block;
    }
  }
`;
