import {Suspense} from 'react';

import useCurrentUserQuery from '@/shared/queries/useCurrentUserQuery';
import {ApiErrorBoundary} from '@/shared/ui/api-error-boundary';
import {ErrorMessage} from '@/shared/ui/error-message';
import {Loading} from '@/shared/ui/loading';
import {useQueryClient} from '@tanstack/react-query';

import PassnoteApplyList from '../components/PassnoteApplyList';
import usePassnoteApplyPassListQuery, {
  getPassnoteApplyPassListQueryKeys,
} from '../queries/usePassnoteApplyPassListQuery';

import PassnoteApplyPassList from './PassnoteApplyPassList';

const PassnoteApplyPassListSuspense = () => {
  const {user} = useCurrentUserQuery();
  const queryClient = useQueryClient();

  const onReset = () => {
    queryClient.resetQueries({queryKey: getPassnoteApplyPassListQueryKeys()});
  };

  const {data} = usePassnoteApplyPassListQuery(user?.institutionId, {
    enabled: false,
  });

  return (
    <PassnoteApplyList title="이력서 합격자 리스트" listCount={data?.length}>
      <ApiErrorBoundary fallback={ErrorMessage} onReset={onReset}>
        <Suspense fallback={<Loading />}>
          <PassnoteApplyPassList key={user.institutionId} />
        </Suspense>
      </ApiErrorBoundary>
    </PassnoteApplyList>
  );
};

export default PassnoteApplyPassListSuspense;
