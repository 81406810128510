import {SearchCompanyNamesButton} from '@/shared/ui/search-compnay-names-button';
import {SortButton} from '@/shared/ui/sort-button';
import {TableHead} from '@/shared/ui/table/table-head';

import PassnoteApplyList from '../components/PassnoteApplyList';
import {PassnoteApplyHire} from '../queries/usePassnoteApplyPassHireQuery';

const PassnoteApplyHireList: React.FC = () => {
  const data: Partial<PassnoteApplyHire>[] = [
    {
      graduateId: 1,
      courseName: 'PO 챌린지 2차',
      graduateName: '박',
      employmentDate: '2023.11.26',
      companyName: '원**',
    },
    {
      graduateId: 2,
      courseName: '마케팅 챌린지 6차',
      graduateName: '김',
      employmentDate: '2023.11.25',
      companyName: '넥****',
    },
    {
      graduateId: 3,
      courseName: 'PO 챌린지 2차',
      graduateName: '이',
      employmentDate: '2023.11.26',
      companyName: '쿠*',
    },
    {
      graduateId: 4,
      courseName: '마케팅 챌린지 6차',
      graduateName: '이',
      employmentDate: '2023.11.25',
      companyName: '당*',
    },
    {
      graduateId: 5,
      courseName: 'PO 챌린지 2차',
      graduateName: '김',
      employmentDate: '2023.11.26',
      companyName: '엔****',
    },
    {
      graduateId: 6,
      courseName: '마케팅 챌린지 6차',
      graduateName: '박',
      employmentDate: '2023.11.25',
      companyName: '한******',
    },
    {
      graduateId: 7,
      courseName: 'PO 챌린지 2차',
      graduateName: '최',
      employmentDate: '2023.11.26',
      companyName: '무**',
    },
    {
      graduateId: 8,
      courseName: '마케팅 챌린지 6차',
      graduateName: '정',
      employmentDate: '2023.11.25',
      companyName: '우*****',
    },
  ];

  return (
    <PassnoteApplyList title="최종 취업자 리스트" listCount={data?.length}>
      <table>
        <colgroup>
          <col width="20%" />
          <col width="32%" />
          <col width="20%" />
          <col width="28%" />
        </colgroup>
        <TableHead>
          <th>이름</th>
          <th>코스명</th>
          <th>
            <SortButton onClick={null} direction={null} text="취업일" />
          </th>
          <th>취업 회사 정보</th>
        </TableHead>
        <tbody>
          {data?.map(item => (
            <tr key={`${item.courseId}_${item.graduateId}_${item.kreditjobId}`}>
              <td>{item.graduateName}**</td>
              <td>{item.courseName}</td>
              <td>{item.employmentDate}</td>
              <td>
                <SearchCompanyNamesButton
                  institutionId={null}
                  kreditjobId={null}
                  companyName={item.companyName}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </PassnoteApplyList>
  );
};

export default PassnoteApplyHireList;
